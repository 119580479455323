export const environment = {
    production: false,
    dbJsonConfigFile: 'db_reports.test.json',
    apiBaseUrl: '',
    dcEnvironments: ['test', 'prod'],
    dscoEnvironments: ['test', 'staging'],
    rtbfEnvironments: ['dev'],
    pulseEnvironments: ['test', 'dev'],
    pulseCupAdapterEnvironments: ['test'],
    searchBuckets: ['arcfs-archive', 'livefs-archive24'],
    optiksEnvironments: ['test', 'staging'],
};
